/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import url('assets/fonts/clashdisplay/clash-display.css');
@import url('assets/fonts/satoshi/satoshi.css');
@import "bootstrap/dist/css/bootstrap.css";
// @import "swiper/swiper-bundle.min.css";
@import "swiper/css";
@import "swiper/css/bundle";
@import "swiper/css/pagination";
@import "swiper/css/navigation";
@import '@ctrl/ngx-emoji-mart/picker';

// Fonts Clash Display
// font-family: 'ClashDisplay-Variable';
// font-family: 'ClashDisplay-Extralight';
// font-family: 'ClashDisplay-Light';
// font-family: 'ClashDisplay-Regular';
// font-family: 'ClashDisplay-Medium';
// font-family: 'ClashDisplay-Semibold';
// font-family: 'ClashDisplay-Bold';

// Fonts Satoshi
// font-family: 'Satoshi-Variable';
// font-family: 'Satoshi-VariableItalic';
// font-family: 'Satoshi-Light';
// font-family: 'Satoshi-LightItalic';
// font-family: 'Satoshi-Regular';
// font-family: 'Satoshi-Italic';
// font-family: 'Satoshi-Medium';
// font-family: 'Satoshi-MediumItalic';
// font-family: 'Satoshi-Bold';
// font-family: 'Satoshi-BoldItalic';
// font-family: 'Satoshi-Black';
// font-family: 'Satoshi-BlackItalic';

// Light Theme Text
$dark-text: #1e1e2b;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #f8f8f8;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f8f8f8, 0.04);
$light-bg-alpha-12: rgba(#f8f8f8, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

:root {
    --color-text-white: #fff;
    --color-text: #4D4D4D;
    --color-primary: #FF4D80;
    --color-primary-background: #ff4d7f3f;
    --color-primary-light: #ff4d7f12;
    --color-light: #AAAAAA;
    --color-background: #F8F8F8;
    --color-red: #ff0000;
    --color-orange: #FF763A;
    --color-green: #2DAE29;
    --color-blue: #13CDE2;
    --bs-body-color: #4D4D4D;
    --bs-primary: #1E1E2B;
    --dynamic-parrallax: 0;
    --bs-borde: #E8E8E8;
    --color-grey: #7A7A7A;
}

body {
    --primary-color: #ff4d80;
    --primary-lighter-color: #ffcad9;
    --primary-darker-color: #ff3463;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
    margin: 0;
    font-family: "Satoshi-Regular";
    background-color: var(--color-background) !important;
    text-decoration: none;
    --accent-color: #ff4d80;
    --accent-lighter-color: #ffcad9;
    --accent-darker-color: #ff3463;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
    --text-color: #303030;
    --error-color: #ff3463;
    --border-color: #ccc;
}

$mat-primary: (
    main: #ff4d80,
    lighter: #ffcad9,
    darker: #ff3463,
    200: #ff4d80,
    // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
    main: #ff4d80,
    lighter: #ffcad9,
    darker: #ff3463,
    200: #ff4d80,
    // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
);

$altTheme: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
}

*:focus-visible {
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline-style: none !important;
}

input:focus,
.btn-link:focus {
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;

}

.text-primary {
    color: var(--color-primary) !important;
}

.text-light {
    color: var(--color-light) !important;
}

.icon-primary {
    color: var(--color-primary);
}

.content-icon {
    padding: 0px 5px;
    cursor: pointer;

    &:hover * {
        color: var(--color-primary);
    }
}

.menuContentCustom {
    font-family: 'Satoshi-Medium';
    font-size: 1rem;
}

a {
    color: var(--color-primary);
    cursor: pointer;
}

a:hover {
    color: var(--color-primary);
    opacity: 70%;
}

.background-primary-hover:hover {
    color: var(--color-primary);
}

.mat-mdc-badge-content {
    background-color: var(--color-primary);
}

@media (min-width: 576px) and (max-width: 767px) {
    .container {
        max-width: 650px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        max-width: 860px;
    }
}

@media (min-width: 2000px) {
    .container-results-cards {
        // max-width: 1900px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 95%;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1536px;
    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 95%;
    }
}

.container-text-white,
.container-text-white>* {
    color: var(--color-text-white) !important;
}

.info-lok {
    line-height: 18px;
    margin-bottom: 28px;
}


// Swiper
.swiper-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.swiper-wrapper {
    padding-bottom: 40px;
}

.swiper-container {
    height: 100%;
}

.swiper-pagination-bullet-active {
    background-color: var(--color-primary) !important;
}

.swiper-horizontal>.swiper-pagination-bullets {
    bottom: 0px !important;
}

.pagination-custom {
    width: 12px;
    height: 12px;
}

.pointer-in {
    cursor: pointer;
}

// Fonts
h1,
.h1 {
    font-family: 'ClashDisplay-Medium';
    font-size: 3.125rem;

    @media (max-width: 578px) {
        font-size: 1.875rem;
    }
}

h2,
.h2 {
    font-family: 'ClashDisplay-Medium';
    font-size: 1.875rem;
}

h3,
.h3 {
    font-family: 'ClashDisplay-Medium';
    font-size: 1.5rem;
}

p {
    font-family: 'Satoshi-Regular';
}

.clash-medium {
    font-family: 'ClashDisplay-Medium';
    font-size: 1.25rem;
}

.satoshi-normal {
    font-family: 'Satoshi-Regular';
    font-size: 1.25rem;
}

.title-page {
    font-family: 'ClashDisplay-Medium';
    font-size: 2.5rem;
    color: var(--bs-primary);
}

@media (max-width: 768px) {
    .title-page {
        font-size: 1.875rem;
    }
}

// Header
.navbar-toggler {
    border: none;
    padding: 0px;
}

.not-results {
    min-height: 50%;
}

// Buttons
.primary-button,
.cancel-button {
    border-radius: 32px;
    border: none;
    padding: 0px 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: 'Satoshi-Regular';
    font-size: 1rem;
    background-color: var(--color-primary);
    color: var(--color-text-white);
}

.cancel-button {
    border: 1px solid var(--color-light);
    color: var(--color-primary);
    background-color: white;
}

.cancel-button:hover {
    border-color: var(--color-primary);
}

.primary-button:focus {
    background-color: var(--color-primary);
    color: white;
}

.primary-button:hover {
    background-color: var(--color-primary-background);
}

.primary-button.solid:hover {
    background-color: var(--color-primary);
    opacity: 0.8;
}

// Input
.custom-form .input-custom,
.container-form,
.mat-mdc-input-element {
    font-family: 'Satoshi-Regular' !important;
    background-color: transparent;
    caret-color: var(--color-primary) !important;
}

.container-form .input-custom {
    caret-color: var(--color-primary);
    color: white !important;
    -webkit-text-fill-color: white !important;
    opacity: 1;
    /* Firefox */
    background-color: transparent !important;
    box-shadow: 0 0 0 30px transparent inset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-mdc-form-field-flex,
.mat-form-field-appearance-fill .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

// Component filtros
.option-view {
    margin-right: 22px;
}

.filter-btn {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 3px 50px #0000000F;
    width: 126px;
    height: 40px;
    margin-left: 12px;

    i {
        margin-left: 20px;
    }

    @media (max-width: 567px) {
        margin-left: auto;
    }
}

.filters-container {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Satoshi-Medium';
    row-gap: 15px;
}

@media (max-width: 991px) {
    .filters-container {
        display: flex !important;
        flex-direction: column;
    }
}

// Event
.tag-new {
    background-color: var(--color-blue);
}

.tag-fire {
    background-color: var(--color-orange);
}

.tag-today {
    background-color: var(--color-green);
}

.title-section {
    color: var(--bs-primary);
    font-size: 1.313rem;
    font-family: 'ClashDisplay-Medium';

    i {
        margin-right: 8px;
    }
}

.appear-animation {
    -webkit-animation: fadeIn 500ms linear;
    -ms-animation: fadeIn 500ms linear;
    -moz-animation: fadeIn 500ms linear;
    -o-animation: fadeIn 500ms linear;
    animation: fadeIn 500ms linear;
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

// Select
.container-select-border {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    border: 2px solid #fff;
    min-width: 260px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.container-select-border:has(.mat-focused) {
    min-width: 300px;
}

.mat-mdc-option:not(.mat-mdc-option-multiple) {
    .mat-pseudo-checkbox {
        display: none;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.panelHomeCity .mat-mdc-option .mdc-list-item__primary-text {
    color: var(--color-text);
}

.select-white,
.select-white::placeholder {
    -webkit-text-fill-color: white !important;
    opacity: 1 !important;
    font-family: 'ClashDisplay-Medium' !important;
    font-size: 1.25rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.select-white .mat-mdc-select-trigger .mat-mdc-select-value {
    color: var(--color-text-white);

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-mdc-select-placeholder {
        color: var(--color-text-white);
        font-family: 'ClashDisplay-Medium';
        font-size: 1.25rem;
    }
}

.container-select-border:has(.mat-focused) {
    .select-white::placeholder {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.375) !important;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field-underline {
    display: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-text-field-wrapper,
.mat-mdc-form-field-flex {
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
// .mat-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) * {
    color: var(--color-primary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select-panel-wrap {
    position: relative;
    border-radius: 20px;
}

.filterCategories,
.panelfilter,
.optionsTicket,
.selectPlaces,
.selectPlacePanel,
.filterControl {
    transform: none !important;
}

.filterCategories {
    min-height: 480px;
    min-width: 350px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.filterCategories,
.mat-mdc-autocomplete-panel,
.filterControl {
    border: none !important;
    // border-bottom-left-radius: 20px !important;
    // border-bottom-right-radius: 20px !important;
    box-shadow: 0 10px 10px 0px rgb(0 0 0 / 5%) !important;
}

.cdk-overlay-pane {
    position: absolute;
    transform: none !important;
}

.filterCategories .mat-mdc-option {
    padding-right: 3rem;
    padding-left: 28px;
    min-height: 60px;
    border-bottom: 1px solid var(--bs-borde);
}

.selectPanelControl,
.selectEventsReports {
    padding: 0px !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 8px !important;
    box-shadow: 0 10px 10px 0px rgb(0 0 0 / 5%) !important;
    margin-top: 6px !important;

    .mdc-list-item__primary-text {
        color: var(--bs-primary);
        font-family: 'Satoshi-Regular' !important;
        font-size: 14px !important;
    }

    .mat-mdc-option {
        padding-right: 1.5rem;
        padding-left: 28px;
        min-height: 45px;

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--bs-borde);
        }
    }
}

.selectEventsReports {
    background-color: #F1F1F1;
    scrollbar-color: #DADADA #F1F1F1;

    .mat-pseudo-checkbox-full:not(.mat-pseudo-checkbox-checked) {
        background-color: white;
    }

    .mat-pseudo-checkbox-full {
        border: 1px solid;
        width: 14px;
        height: 14px;
        margin-right: 6px;
    }

    .mat-mdc-option {
        padding-right: 14px;
        padding-left: 14px;
        min-height: 40px;

        &:not(:last-of-type) {
            border-bottom: 1px solid #D6D6D6;
        }

        .name-event {
            font-family: 'Satoshi-Medium';
            font-size: 15px;
            color: var(--bs-primary);
        }

        .dates-event {
            font-size: 15px;
            color: var(--color-grey);

            i {
                color: #C5C5C5;
            }
        }
    }
}

.mat-mdc-tooltip.tooltip-reports {
    --mdc-plain-tooltip-container-color: #F1F1F1;
    --mdc-plain-tooltip-supporting-text-color: #4D4D4D;
    --mdc-plain-tooltip-supporting-text-font: 'Satoshi-Regular';
    --mdc-plain-tooltip-supporting-text-size: 12px;
    // --mdc-plain-tooltip-supporting-text-tracking: 0;
}


.filterCategories .mat-mdc-option .mdc-list-item__primary-text,
.panelfilter .mat-mdc-option .mdc-list-item__primary-text,
.filterPanel .mat-mdc-option .mdc-list-item__primary-text,
.filterControl .mat-mdc-option .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    font-family: 'Satoshi-Regular';

    i {
        color: var(--color-primary);
        font-size: 1.875rem;
        min-width: 50px;
        width: 50px;
        // margin-right: 17px;
    }
}

.optionsTicket {
    min-width: 200px !important;
}

.optionsFormPayment {
    min-width: max(90%, 100px) !important;
}

.filter-place,
.options-bonds,
.filter-areas-seats {
    min-height: 40px;
    width: 100%;
    border: 1px solid var(--bs-primary);
    border-radius: 18px;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper {
        padding-right: 10px !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        padding: 0px 14px;
        border: 0px;
        min-height: 35px;
        display: flex;
        align-items: center;
    }

    .mat-mdc-select {
        font-family: 'Satoshi-Regular';
    }
}

.filter-areas-seats {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        min-height: 38px;
    }
}

.selectAreas {
    border-radius: 14px !important;
    border: 1px solid #B5B5B5;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field-infix {
        border: 0px;
    }

    .option-area {
        margin: 6px;
        border-radius: 14px !important;
        background-color: white;
        min-height: 35px;
        padding: 6px 12px;
        height: 2.5em !important;
        line-height: 2.5em !important;
    }

    mat-option:hover:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    mat-option:focus:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    mat-option.mdc-list-item--selected .mdc-list-item__primary-text {
        // background: white !important;
        // outline: 1px solid var(--color-primary);
    }

    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .option-area .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .area-item {
        display: flex;
        gap: 10px;

        .color-area {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }
}

.selectPlaces {
    min-width: 100% !important;
    border: 1px solid #B5B5B5;
    border-radius: 14px !important;
    background-color: #F1F1F1;
    padding: 6px !important;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field-infix {
        border: 0px;
    }

    .option-place {
        margin: 10px;
        border-radius: 14px !important;
        background-color: white;
        min-height: 60px;
        padding: 5px 12px;
    }

    mat-mdc-option:hover:not(.mdc-list-item--disabled),
    mat-mdc-option:focus:not(.mdc-list-item--disabled),
    mat-mdc-option.mat-selected {
        background: white !important;
        outline: 1px solid var(--color-primary);
    }

    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .option-place .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .image-place {
        max-width: 40px;
        height: 40px;
        border: 1px solid #DADADA;
        box-shadow: 0px 3px 50px #00000017;
        border-radius: 12px;
        overflow: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .name-place {
        font-family: 'ClashDisplay-Medium';
        font-size: 16px;
        font-weight: normal;
    }
}

.optionsTicket .mat-mdc-option,
.optionsFormPayment .mat-mdc-option {
    padding: 0px 12px;
    min-height: 40px;
    border-bottom: 1px solid var(--bs-borde);
    color: var(--bs-primary);
    font-family: 'Satoshi-Regular';
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-mdc-autocomplete-panel {
    border-radius: 4px;
}

.cdk-global-overlay-wrapper:has(.animation-right-dialog) {
    -webkit-animation: dialogRightAnimation 250ms linear;
    -ms-animation: dialogRightAnimation 250ms linear;
    -moz-animation: dialogRightAnimation 250ms linear;
    -o-animation: dialogRightAnimation 250ms linear;
    animation: dialogRightAnimation 250ms linear;
}

.cdk-global-overlay-wrapper:has(.animation-left-dialog) {
    -webkit-animation: dialogLeftAnimation 250ms linear;
    -ms-animation: dialogLeftAnimation 250ms linear;
    -moz-animation: dialogLeftAnimation 250ms linear;
    -o-animation: dialogLeftAnimation 250ms linear;
    animation: dialogLeftAnimation 250ms linear;
}

@keyframes dialogRightAnimation {
    0% {
        left: 100%;
    }

    100% {
        left: 0%;
    }
}

@keyframes dialogLeftAnimation {
    0% {
        left: -100%;
    }

    100% {
        left: 0%;
    }
}

// Scrollbar general
.up-scroll {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: var(--color-primary);
    display: grid;
    justify-content: center;
    align-items: center;
    color: white;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar:hover {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: white;
    border-color: #EEEEEE;
    box-shadow: inset 0 0 2px #EEEEEE;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: var(--color-light);
    border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-light);
}

.list-select-tickets,
.container-float {
    overflow-y: scroll;
    scrollbar-color: var(--color-primary) white;
    scrollbar-width: thin;
}

.list-select-tickets::-webkit-scrollbar {
    width: 4px;
}

.list-select-tickets::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 5px;
}

.list-select-tickets::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary);
}

// Scroll panel
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    overflow-y: scroll;
    scrollbar-color: var(--color-primary) white;
    scrollbar-width: thin;
}

.mat-mdc-select-panel::-webkit-scrollbar {
    width: 5px;
}

.mat-mdc-select-panel::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 5px;
}

.mat-mdc-select-panel::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary);
}

// Lottie Loading
.loading-container {
    height: 80px;
    width: 120px;
    justify-self: center;
}

// Dialogs
.cdk-overlay-container {
    z-index: 1050;
}

.mat-mdc-dialog-container {
    color: var(--bs-primary);

    .mat-mdc-dialog-surface {
        padding: 16px;
        border-radius: 8px;
    }
}

.cdk-global-overlay-wrapper:has(.panel-dialog-left) {
    @media (max-width: 1199px) {
        justify-content: flex-start !important;
    }

    @media (min-width: 1200px) {
        justify-content: flex-end !important;
    }
}

.panel-dialog,
.main-menu-dialog {
    height: 100%;
    z-index: 1050 !important;

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-surface {
            border-radius: 0px;
            padding: 0px;
        }
    }

    @media (max-width: 568px) {
        width: 100%;
        min-width: 100%;

        .mat-mdc-dialog-container {
            .mat-mdc-dialog-surface {
                border-radius: 0px;
                padding: 0px;
            }
        }
    }
}

.info-dialog {
    min-width: 250px;
}

.info-dialog .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
        border-radius: 10px;
    }
}

// Chatbot
.info-dialog,
.dialog-chatbot {
    min-width: 250px;
}

.dialog-chatbot {
    max-width: 470px !important;
    width: 470px;
    height: 600px;
    margin-bottom: 20px !important;
    margin-right: 20px !important;

    .mat-dialog-container {
        padding: 0px;
        border-radius: 20px;
        overflow: hidden;
    }
}

@media (max-width: 576px) {
    .dialog-chatbot {
        width: 100% !important;
        max-width: 100% !important;
        height: 100%;
        margin: 0px !important;

        .mat-dialog-container {
            padding: 0px;
            border-radius: 0px;
            overflow: hidden;
        }
    }
}

// Subscribers
.dialog-login,
.dialog-subscribers {
    @media (max-width: 575px) {
        max-width: 100vw !important;
    }
}

.dialog-login .mat-mdc-dialog-container,
.dialog-subscribers .mat-mdc-dialog-container {
    max-height: 100vh;

    @media (max-width: 575px) {
        max-width: 100%;

        .mat-mdc-dialog-surface {
            padding: 16px;
        }
    }
}

.panel-seats,
.info-abono-dialog {
    @media (max-width: 1199px) {
        max-width: 90vw !important;
    }

    @media (max-width: 575px) {
        max-width: 100vw !important;
        height: 100vh;
        width: 100vw;
    }
}

.not-padding .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
        padding: 0px;
    }
}

.dialog-content {
    // margin-top: 86px;
    // height: 90%;
    position: relative;
    // padding-top: 25px;
    height: 100%;
}

.dialog-edit-lok {
    min-width: 450px;
    width: 50%;
    max-width: 500px;

    .form-dates-lok {
        min-height: 500px !important;
    }
}

@media (max-width: 568px) {
    .dialog-edit-lok {
        min-width: 350px;
    }
}

.title-filter,
.title-dialog {
    font-family: 'ClashDisplay-Regular';
    font-size: 1.5rem;
}

@media (max-width: 568px) {
    .title-filter {
        font-size: 1.313rem;
        color: var(--color-primary);
    }
}


// Sliders {
.custom-slider .ngx-slider .ngx-slider-bar {
    background: #D2D2D2;
    height: 2px;
    width: 100%;
}

.custom-slider .ngx-slider .ngx-slider-selection {
    background: var(--color-primary);
}

.custom-slider .ngx-slider .ngx-slider-pointer {
    width: 20px;
    height: 20px;
    top: -8px;
    /* to remove the default positioning */
    bottom: 0;
    background: white;
    border-radius: 50%;
    border: 1px solid #E1E1E1;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
    display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
    color: var(--bs-primary);
}

// Panel Accordion
.mat-expansion-panel {
    margin: 8px 0 !important;
    border-radius: 8px !important;
    box-shadow: 3px 3px 20px #00000017 !important;
}

.mat-expansion-panel.mat-expanded {
    border-radius: 8px;
}

// .mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
//     border-radius: 0px;
// }
.mat-expansion-panel-header,
.mat-expansion-panel-body {
    font-family: 'Satoshi-Regular';

    p {
        margin-bottom: 0px;
    }

    .mat-expansion-indicator::after {
        color: var(--color-primary);
    }

    strong {
        font-family: 'Satoshi-Bold';
    }
}

.mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    font-family: 'Satoshi-Bold';
}

b,
strong {
    font-family: 'Satoshi-Bold';
}


// Post
.cita-wp {
    margin: 30px 0px;
    padding-left: 12px !important;
    border-left: 3px solid var(--color-primary);
    font-family: 'Satoshi-MediumItalic';
    font-size: 1.25rem;
}

.short-text {
    font-size: 14px;
    overflow: hidden;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    -ms-line-clamp: 3;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
}

// Checkbox
.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-primary .mat-mdc-ripple-element,
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-primary .mat-mdc-ripple-element {
    background: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
    background-color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-frame {
    border: 1.5px solid #DCDCDC !important;
}

// Dialog new lok
.new-lok-dialog {

    // overflow: auto;
    .mat-mdc-dialog-container {
        height: 100%;
        padding: 0;
        width: 100vw;
        max-width: 650px;
        max-height: 90vh;
        overflow: auto;

        .mat-mdc-dialog-surface {
            padding: 0px;
        }
    }

    @media (max-width: 400px) {
        // overflow: hidden;
        max-width: 100vw !important;
        width: 100%;
        height: 100%;

        .mat-mdc-dialog-container {
            border-radius: 0px;
            max-height: 100vh;

            .mat-mdc-dialog-surface {
                border-radius: 0px;
            }
        }

        // .container-new-lok {
        // display: grid;
        // grid-template-rows: min-content auto min-content;
        // form {
        // height: 100%;
        // overflow: auto;
        // }
        // }
    }
}

.hours-lok-dialog {
    max-width: 380px !important;
    overflow-y: auto;
    border-radius: 20px;

    .mat-mdc-dialog-container {
        overflow-y: auto;

        .mat-mdc-dialog-surface {
            border-radius: 20px;
        }
    }
}

.form-new-lok {
    padding: 20px;
    min-height: 400px;

    input {
        height: 50px;
        border: none;
        border-style: hidden !important;
        outline-style: none !important;
        font-size: 0.875rem;
        // -webkit-text-fill-color: var(--bs-primary);
        caret-color: var(--color-primary) !important;
        background-color: transparent;
        font-family: 'Satoshi-Regular';
        color: var(--bs-primary);
    }

    .mat-mdc-input-element {
        height: 20px;
    }

    .mat-mdc-form-field.mat-focused+.divider-focused {
        border-top-color: var(--color-primary);
    }

    i {
        color: var(--bs-primary);
        font-size: 1rem;
        margin-right: 10px;
    }

    .mat-mdc-form-field:not(.filter-place):not(.filter-areas-seats) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        min-height: 50px;
        padding-top: 18px;
    }
}

.btn-new-form {
    padding: 18px 0px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    bottom: 0;
}

// Styles inputs
.btn-save {
    background-color: var(--color-primary);
    color: white;
}

.disabled-btn {
    background-color: #F1F1F1;
    color: var(--color-light);
    pointer-events: none;
}

.mat-mdc-form-field {
    font-family: 'Satoshi-Regular';
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-mdc-floating-label {
    color: var(--color-light);
}

.mat-stepper-horizontal {
    height: 100%;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: transparent;
}

.mat-horizontal-stepper-header-container {
    display: none !important;
}

.mat-horizontal-content-container {
    padding: 0px !important;
    height: 100%;
    overflow-y: auto !important;
}

.mat-horizontal-stepper-wrapper {
    height: 100%;
}

.mat-horizontal-stepper-content {
    height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

//custom sizes
mat-slide-toggle {
    svg {
        display: none !important;
    }

    button {
        width: 43px !important;
    }

    .mdc-switch__handle {
        width: 15px !important;
        height: 15px !important;
    }

    .mdc-switch__handle-track {
        width: calc(100% - 25px) !important;
        margin-left: 5px !important;
    }

    .mdc-switch__track {
        height: 24px !important;
        width: 43px !important;
        border-radius: 25px !important;
        border: 2px solid #FFFFFF !important;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-new-lok {
    height: 100%;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field,
    .mat-mdc-floating-label {
        font-family: 'Satoshi-Regular';
    }

    .count-letters {
        color: var(--bs-primary);
        opacity: 40%;
    }

    .mat-mdc-floating-label {
        top: 22px !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--focused) {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-floating-label {
            margin-top: 5px;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field:not(.mat-mdc-form-field-should-float) {
        .count-letters {
            display: none;
        }
    }

    .mat-mdc-form-field-invalid {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-floating-label {
            color: var(--bs-primary);
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field .mat-mdc-floating-label {
        color: var(--bs-body-color);
        opacity: 1;
        display: flex;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-focused.mat-mdc-form-field-invalid .mat-mdc-floating-label,
    .mat-mdc-form-field-should-float .mat-mdc-floating-label {
        color: var(--color-primary);
        opacity: 1;
    }

    .mat-focused {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-floating-label {
            color: var(--color-primary);
            opacity: 1;
            margin-top: 5px;
            // font-size: 0.875rem;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field:not(.mat-focused) .mat-mdc-form-field-empty.mat-mdc-floating-label {
        position: static;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
        align-self: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset;
        box-shadow: 0 0 0 30px white inset;
        outline: none !important;
        -webkit-text-fill-color: var(--bs-primary);
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--bs-primary);
        border: none;
    }

}

.edit-ticket {
    padding: 0px;
}

.pac-container {
    z-index: 1052;
}

// Tabs style
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-ripple-element,
.mat-mdc-button-persistent-ripple,
.mat-mdc-tab-label-focused {
    background-color: transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-labels {
    justify-content: space-around;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.owner-tabs .mat-mdc-tab-labels {
    justify-content: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-label {
    cursor: pointer;
    color: var(--bs-primary);
    font-family: 'ClashDisplay-Medium';
    font-size: 1.5rem;
    max-width: fit-content;
}

.mat-mdc-tab-header {
    border: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-label-active {
    color: var(--bs-primary) !important;
    opacity: 1 !important;
}

.mat-mdc-tab-body {
    margin-top: 70px;
}

.history-tabs {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab-labels {
        justify-content: space-around;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-mdc-tab-label {
            font-size: 1.25rem;
        }
    }

    .mat-mdc-tab-body {
        margin-top: 35px;
    }
}

.selected-tab-primary.mat-primary .mat-mdc-ink-bar {
    background: var(--color-primary);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 2px;
    width: fit-content;
}

// Mapa
.card-map-event {
    padding: 10px;
}

.title-map-card {
    font-family: 'Satoshi-Bold';
    font-size: 1rem;
}

.body-map-card {
    font-family: 'Satoshi-Regular';
    font-size: 0.875rem;
}

.link-map-card {
    font-family: 'Satoshi-Medium';
    font-size: 1rem;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
}

.mdc-line-ripple,
.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.mdc-slider {
    height: 28px !important;
    --mdc-slider-handle-width: 15px;
    --mdc-slider-handle-height: 15px;
    // --mat-mdc-slider-value-indicator-opacity

    .mdc-slider__thumb {
        left: -14px !important;
    }

    .mdc-slider__thumb,
    .mat-ripple-element {
        height: 28px !important;
        width: 28px !important;
    }

    .mdc-slider__thumb--with-indicator {}
}

.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple,
.mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple {
    left: 0 !important;
    top: 0 !important;
}

.content-wp-page {
    padding-top: 150px;

    &.content-terms-dialog {
        padding-top: 0px;
    }

    * {
        font-family: 'Satoshi-Regular';
        font-size: 1rem;
        text-align: justify;
        box-sizing: border-box;
        font-weight: 400;
    }

    h1, h1 * {
        font-family: 'ClashDisplay-Medium';
        font-size: 2.188rem;
        text-align: center;
        margin-bottom: 30px;
        color: var(--color-primary);
    }

    h2, h2 * {
        font-family: 'ClashDisplay-Medium';
        font-size: 1.25rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    h3, h3 * {
        font-family: 'Satoshi-Medium';
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    p, h4, h5, h6, li, ol, ul {
        strong {
            font-family: 'Satoshi-Bold';
            font-weight: bold;
        }
    }
}